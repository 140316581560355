
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import BirthDateInput from '@/components/forms/BirthDateInput.vue';
    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import PhoneInput from '@/components/forms/PhoneInput.vue';
    import {Prop} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';
    import {Watch} from 'vue-property-decorator';

    import {
      ISpecies,
      IBreed,
      IUser,
      IPlaceSuggestion,
    } from '@/types';

    import {
      AnimalModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');
    const animalNamespace = namespace('animal');

    @Component<GuestForm>({
      components: {
        PlaceInput,
        PhoneInput,
        BirthDateInput,
      },
    })
    export default class GuestForm extends Vue {
      public lastname: string = '';
      public firstname: string = '';
      public step: number = 1;
      public animal: any = {};
      public formValid: boolean = true;
      public zip: string = '';
      public city: IPlaceSuggestion|null = null;
      public cellPhone: string|null = null;
      public emailRules = formHelper.getEmailsRules();
      public email: string = '';
      public checkbox: boolean|null = null;
      public loadingUrl: boolean = false;

      @accountNamespace.Getter('loggedUser')
      public loggedUser!: IUser;

      @animalNamespace.Getter('speciesList')
      public speciesList!: ISpecies[];

      @animalNamespace.Getter('animalsInsuranceList')
      public animalsInsurable!: ISpecies[];

      @animalNamespace.Getter('breedsList')
      public breedsList!: IBreed[];

      @Prop({
            type: Array,
            required: false,
        })
      public utms!: Record<string, []>;

      public sexRules = [
        (v: string) => !!v || 'Le sexe est requis',
      ];

      public nameRules = [
        (v: string) => !!v || 'Le champs est requis',
      ];

      public speciesRules = [
        (v: string) => !!v || 'L\'espèce est requise',
      ];

      public validateCheckbox = [
        (v: boolean) => v || 'Vous devez accepter les conditions',
      ];

      public zipRules = [
        (v: string) => !!v || 'Ce champs est requis',
        (v: string) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || 'doit etre un code postal',
      ];

      public cityRules = [
        (v: string) => !!v || 'Ce champs est requis',
      ];

      get stepNext() {
        return this.animal.species && this.animal.sex && this.animal.name &&  this.animal.birth_date && this.checkbox;
      }

      get stepFinal() {
        return (this.email && this.cellPhone && this.firstname && this.lastname && this.zip && this.city) || !!this.loggedUser;
      }

      get filteredBreedsList() {
        if (!this.animal.species) {
          return [];
        }

        return this.breedsList.filter((breed: IBreed) => breed.species_id === this.animal.species.id);
      }

      public getZipcode () {
        let city = JSON.parse(JSON.stringify(this.city));
        this.zip = city.raw_data.address.postalCode;
      }

      public canInsureAnimalGuest() {
        if (!this.animal.breed) {
          this.animal.breed  = {
            id: null,
          };
        }

        const params = {
          user: {
            lastname : this.lastname,
            firstname : this.firstname,
            postalCode: this.zip,
            email: this.email,
            city: this.city?.title.split(','),
            cellPhone: this.cellPhone,
          },
          animal: {
            name: this.animal.name,
            birth_date: this.animal.birth_date,
            specie: this.animal.species.id,
            breed: this.animal.breed.id,
            gender: this.animal.sex,
          },
        };
        this.getQuotationGuest(params);
      }

      public buildQueryString() {
        const utm: any = this.utms;
        return utm.map((obj: Object[]) => {
          const entries = Object.entries(obj);
          return entries.map(([key, value]: any) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
        }).join('&');
      }

      public getQuotationGuest(params: any) {
        this.loadingUrl = true;
        
        getModule(AnimalModule, this.$store)
          .santevetGetQuotationGuest({params})
            .then((data) => {
              let href = `https://assurance.santevet.com/quote-species?`+this.buildQueryString(); 
              if (data.url) {
                href = data.url + `&`+ this.buildQueryString();
              }
              window.location.href = href;
            },
          ).catch(()=> {
            const href = `https://assurance.santevet.com/quote-species?`+this.buildQueryString();
            window.location.href = href;
          })
        ;
      }

      public mounted() {
        getModule(AnimalModule, this.$store).insuranceAnimalList();
      }

    }

